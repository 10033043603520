import React, { forwardRef } from "react";

// UI
import ProgramTableEmptyState from "./ProgramTableEmptyState";
import { Avatar, Dropdown, IconButton, Popover, Table, Whisper } from "rsuite";
import { MdOutlineMoreHoriz } from "react-icons/md";

// 3rd Party
import { useNavigate } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

interface MenuPopoverProps {
  onClose: () => void;
  right?: number;
  top?: number;
  className?: string;
  rowData: { id: number };
}

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    {dataKey ? (
      <div
        style={{
          width: 40,
          height: 40,
          background: "#f5f5f5",
          borderRadius: 6,
          marginTop: 2,
          overflow: "hidden",
          display: "inline-block",
        }}
      >
        <img src={rowData.logo} width="40" />
      </div>
    ) : (
      <div>
        <Avatar style={{ background: "##428ac9" }}>
          {rowData.name.charAt(0).toUpperCase()}
        </Avatar>
      </div>
    )}
  </Cell>
);

const handleEnterProgram = (id) => {
  window.location.href = `/enter-program/${id}`;
};

const MenuPopover = forwardRef<HTMLDivElement, MenuPopoverProps>(
  ({ onClose, right, top, className, rowData }, ref) => {
    const navigate = useNavigate();

    const handleEdit = (id) => {
      navigate(`/edit/${id}`);
    };

    const handleSelect = (eventKey) => {
      onClose();

      if (eventKey === 1) {
        handleEdit(rowData.id);
      } else if (eventKey === 2) {
        handleEnterProgram(rowData.id);
      }
    };

    return (
      <Popover ref={ref} className={className} style={{ right, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item eventKey={1}>Edit</Dropdown.Item>
          <Dropdown.Item eventKey={2}>Enter Program</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  }
);

const ActionCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props} className="link-group">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={(props, ref) => (
          <MenuPopover {...props} ref={ref} rowData={rowData} />
        )}
      >
        <IconButton appearance="subtle" icon={<MdOutlineMoreHoriz />} />
      </Whisper>
    </Cell>
  );
};

const DashboardProgramTable = ({ tableData, loading }) => {
  const data = React.useMemo(() => tableData, [tableData]);

  return (
    <Table
      data={data}
      autoHeight
      rowHeight={60}
      className="tw-my-6 tw-min-h-full"
      affixHorizontalScrollbar
      // headerHeight={60}
      // rowClassName="tw-text-sm"
      loading={loading}
      renderEmpty={() => <ProgramTableEmptyState />}
    >
      <Column verticalAlign="middle" align="center" minWidth={100}>
        <HeaderCell> </HeaderCell>
        <ImageCell dataKey={"logo"} rowData={undefined} />
      </Column>
      <Column verticalAlign="middle" align="left" flexGrow={2}>
        <HeaderCell>Program Name</HeaderCell>
        <Cell>
          {(rowData) => (
            <span
              onClick={() => handleEnterProgram(rowData.id)}
              className="tw-text-[#428ac9] tw-cursor-pointer"
            >
              {rowData.name}
            </span>
          )}
        </Cell>
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Pairing Method</HeaderCell>
        <Cell dataKey={"pairing_method"} />
      </Column>
      {/* TODO: Disable temporarily */}

      {/* <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Created at</HeaderCell>
        <Cell dataKey={"created_at"} />
      </Column> */}

      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Start date</HeaderCell>
        <Cell dataKey={"from_date"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Invitation type</HeaderCell>
        <Cell dataKey={"invitation_type"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Total Users</HeaderCell>
        <Cell dataKey={"total_users"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Mentees</HeaderCell>
        <Cell dataKey={"mentees"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Mentors</HeaderCell>
        <Cell dataKey={"mentors"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Admins</HeaderCell>
        <Cell dataKey={"admins"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey={"status"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1} fixed="right">
        <HeaderCell> </HeaderCell>
        <ActionCell dataKey={"id"} rowData={undefined} />
      </Column>
    </Table>
  );
};

export default DashboardProgramTable;
