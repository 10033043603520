import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

const endpoint = "/api/enterprise/v1";

export const api = axios.create({ baseURL: endpoint });
const getToken = () =>
  // Cookies.get("enterprise_token") ||
  Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);

export const registerEnterprise = async (data) => {
  return await api.post("/auth/sign_up", data);
};

export const loginEnterprise = async (data) => {
  return await axios.post(
    "/login.json",
    {
      user: {
        ...data,
        type: "enterprise",
      },
    },
    {
      withCredentials: true,
    }
  );
};

export const forgotPassword = async (data) => {
  return await api.post("/auth/forgot_password", data);
};

export const resetPassword = async (data) => {
  return await api.post("/auth/reset_password", data);
};

export const currentEnterprise = async () => {
  return await api.get("/admin/current");
};

export const createEnterprise = async (data) => {
  return await api.post("/admin/enterprise", data);
};

export const getPrograms = async (from_date, to_date) => {
  return await api.get("/programs", {
    params: {
      from_date: moment(from_date).format("YYYY-MM-DD"),
      to_date: moment(to_date).format("YYYY-MM-DD"),
    },
  });
};

export const logoutEnterprise = async () => {
  return await fetch(`/kill-session`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    // credentials: env === "development" || env === "test" ? "same-origin" : "include",
    credentials: "include",
  });
};

export const resendConfirmation = async (data) => {
  return await api.post("/auth/resend_confirmation", data);
};

export const getCountries = async () => {
  return await api.get("/countries");
};

api.interceptors.request.use(
  //set token to header
  function (config) {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  }
);
