import React from "react";
import { FaFilter } from "react-icons/fa";
import { Checkbox, CheckboxGroup, Dropdown, IconButton } from "rsuite";

const FilterButton = ({ state, value }) => {
  const renderButton = (props, ref) => {
    return (
      <IconButton icon={<FaFilter size={16}/>} appearance="ghost" ref={ref} {...props} />
    );
  };

  const handleReset = () => {
    state([]);
  };

  return (
    <Dropdown
      renderToggle={renderButton}
      placement="bottomEnd"
      className="tw-mr-2"
    >
      <Dropdown.Item panel style={{ padding: "5px 10px", width: 200 }}>
        <div className="tw-flex tw-justify-between">
          <div>Filter by</div>
          <div>
            <a onClick={handleReset} style={{ color: "#058CC9" }}>
              Reset
            </a>
          </div>
        </div>
      </Dropdown.Item>
      <Dropdown.Item divider />
      <CheckboxGroup value={value} onChange={(value) => state(value)}>
        <Dropdown.Item className="tw-p-0">
          <Checkbox value="great">Great</Checkbox>
        </Dropdown.Item>
        <Dropdown.Item className="tw-p-0">
          <Checkbox value="fair">Fair</Checkbox>
        </Dropdown.Item>
        <Dropdown.Item className="tw-p-0">
          <Checkbox value="poor">Poor</Checkbox>
        </Dropdown.Item>
      </CheckboxGroup>
    </Dropdown>
  );
};

export default FilterButton;
