import Cookies from "js-cookie";
import React, { createContext, useContext, useReducer } from "react";
import {
  currentEnterprise,
  logoutEnterprise,
} from "../components/enterprise/api";
import { useToaster, Message } from "rsuite";

type Enterprise = {
  id: number;
  slug: string;
  name: string;
  created_at: string;
};

type Admin = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  enterprise: Enterprise[] | [] | null;
  created_at: string;
  confirmed: boolean;
};

interface EnterpriseProps {
  admin: Admin | null;
  jwt_token: string | null;
  enterprises: Enterprise[] | [] | null;
  pwa_token: string | null;
}

interface EnterpriseContextProps {
  state: EnterpriseProps;
  dispatch: React.Dispatch<any>;
  loginAdmin: (data: EnterpriseProps) => void;
  logoutAdmin: () => void;
  toaster: any;
  getCurrentAdmin: () => void;
  loggedIn: boolean;
}

const setInitialState = () => {
  const data = localStorage.getItem("enterprise_state");

  if (data) {
    return JSON.parse(data);
  }

  return {
    admin: null,
    jwt_token: null,
    enterprise: null,
    pwa_token: null,
  };
};

const EnterpriseContext = createContext<EnterpriseContextProps>({
  state: setInitialState(),
  dispatch: () => {},
  loginAdmin: () => {},
  logoutAdmin: () => {},
  toaster: () => {},
  getCurrentAdmin: () => {},
  loggedIn: false,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ENTERPRISE":
      return {
        ...state,
        admin: action.payload.admin,
        enterprises: action.payload.enterprises,
        jwt_token: action.payload.jwt_token,
        pwa_token: action.payload.pwa_token,
      };
    case "LOGOUT":
      return {
        ...state,
        admin: null,
        enterprises: null,
        jwt_token: null,
        pwa_token: null,
      };
    default:
      return state;
  }
};

const message = ({ type, msg }) => (
  <Message showIcon type={type} closable>
    {msg}
  </Message>
);

export const EnterpriseProvider = ({ children, initialState }) => {
  const [loading, setLoading] = React.useState(false);
  const [state, dispatch] = useReducer(
    reducer,
    initialState || setInitialState()
  );
  const toaster = useToaster();
  const isMounted = React.useRef(false);

  const loginAdmin = async (data) => {
    localStorage.setItem("enterprise_state", JSON.stringify(data));
    dispatch({
      type: "SET_ENTERPRISE",
      payload: {
        admin: data.admin,
        enterprises: data.enterprises,
        jwt_token: data.jwt_token,
        pwa_token: data.pwa_token,
      },
    });
    await getCurrentAdmin();
  };

  const logoutAdmin = async () => {
    try {
      setLoading(true);
      await logoutEnterprise();
      localStorage.removeItem("enterprise_state");
      dispatch({
        type: "LOGOUT",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentAdmin = async () => {
    try {
      const { data } = await currentEnterprise();
      localStorage.setItem("enterprise_state", JSON.stringify(data));
      dispatch({
        type: "SET_ENTERPRISE",
        payload: {
          admin: data.admin,
          enterprises: data.enterprises,
          jwt_token: data.jwt_token,
          pwa_token: data.pwa_token,
        },
      });
    } catch (error) {
      console.log(error);
      //check if error 401 and logout
      if (error.response.status === 401) {
        // logoutAdmin();
        toaster.push(
          <Message type="error">
            The session has expired. Please login again.
          </Message>
        );
      }
    }
  };

  const loggedIn = !loading && isMounted.current && state.jwt_token;

  React.useEffect(() => {
    getCurrentAdmin().then(() => {
      isMounted.current = true;
    });
  }, []);

  return (
    <EnterpriseContext.Provider
      value={{
        state,
        loginAdmin,
        logoutAdmin,
        dispatch,
        toaster: (props) => toaster.push(message({ ...props })),
        getCurrentAdmin,
        loggedIn,
      }}
    >
      {children}
    </EnterpriseContext.Provider>
  );
};

export const useEnterprise = () => useContext(EnterpriseContext);
