import React, { useEffect } from "react";
import { Button, Message, toaster } from "rsuite";
import { FutureLabApi } from "../util/api";
import translateText from "../util/translateText";
import mixpanel from "mixpanel-browser";

const EventMentorFollowButton = ({ mentor, user, convertText = null }) => {
  const [follow, setFollow] = React.useState(false);

  useEffect(() => {
    if (user) {
      FutureLabApi({
        method: "get",
        path: `/users/current/follow_status`,
        options: { mentor_id: mentor.user_id },
        success: ({ data }) => {
          setFollow(data);
        },
      });
    }
  }, [follow]);

  const handleFollow = () => {
    FutureLabApi({
      method: "post",
      path: `/users/current/set_follow`,
      options: { mentor_id: mentor.user_id },
      success: ({ data }) => {
        setFollow(data);
      },
    });
    toaster.push(
      <Message showIcon type="success">
        {follow == true
          ? `${translateText(
              convertText,
              "You are no longer following this mentor"
            )}`
          : `${translateText(convertText, "You are following this mentor")}`}
      </Message>
    );
    mixpanel.track("Clicked Follow Button", {
      Name: user.full_name,
      "User Type": user.user_type,
      Action: follow ? "Unfollowing" : "Following",
      "Mentor Name": mentor.full_name,
      "Mentor Id": mentor.user_id,
      Location: "Event Page",
    });
  };

  return (
    <Button appearance="ghost" onClick={() => handleFollow()}>
      {follow == true
        ? `${translateText(convertText, "Unfollow")}`
        : `${translateText(convertText, "+ Follow")}`}
    </Button>
  );
};

export default EventMentorFollowButton;