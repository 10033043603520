// React
import React, { useState, forwardRef, useEffect } from "react";

// FutureLab
import SearchResult from "./search/SearchResult";
import useSearch from "./search/useSearch";
import StarterPackBanner from "./search/component/StarterPackBanner";
import StarterPackModal from "./search/component/StarterPackModal";
import "./search/search.css";

// UI
import {
  Checkbox,
  CheckboxGroup,
  Col,
  FlexboxGrid,
  Input,
  InputGroup,
  CheckPicker,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

// 3rd Party
import { useIntersection } from "@mantine/hooks";

const selectedIndustries = [
  "Business Development",
  "Customer Service",
  "Finance",
  "Operations",
  "Marketing",
  "Engineering",
  "Human Resources",
  "Software Engineering",
  "Data Science",
  "UI/UX",
  "Customer Experience",
];

const Search = ({
  industries,
  mentors: defaultMentors = [],
  totalPages,
  // goals = [],
  currentUser,
  program = null,
}) => {
  const {
    setMentors,
    mentors,
    query,
    loading,
    setQuery,
    handleSearch,
    // handlePopularSearch,
    filter,
    handleAddFilter,
    handleClearFilter,
    handleFetchMore,
    // resultsQuery,
  } = useSearch(defaultMentors, totalPages);
  const { ref: intersectionRef, entry } = useIntersection({
    root: document.querySelector(".portal_dashboard_content"),
    threshold: 0.1,
  });
  // const [showStarterPackModal, setShowStarterPackModal] = useState(false);

  const height = currentUser
    ? program
      ? "xs:tw-h-[calc(100svh-290px)] sm:tw-h-[calc(100svh-181px)] tw-h-[calc(100svh-225px)]"
      : "xs:tw-h-[calc(100svh-350px)] sm:tw-h-[calc(100svh-207px)] tw-h-[calc(100svh-253px)]"
    : "xs:tw-h-[calc(100svh-236px)] sm:tw-h-[calc(100svh-154px)] tw-h-[calc(100svh-154px)]";

  const handleKeyPress = (target) => {
    if (target.code == "Enter") {
      handleSearch();
    }
  };

  const debounceChange = (e) => setQuery(e);

  const filteredIndustries = industries.filter((i) =>
    selectedIndustries.map((v) => v).includes(i)
  );

  useEffect(() => {
    if (entry?.isIntersecting) {
      handleFetchMore();
    }
  }, [entry]);

  console.log(mentors);

  return (
    <FlexboxGrid
      justify="center"
      className={`tw-relative ${
        currentUser ? program && "tw-my-8" : "tw-pt-8"
      }`}
    >
      <FlexboxGrid.Item as={Col} xs={24} md={5} lg={5} xl={5} xxl={4}>
        <div className=" mb-4 tw-justify-between tw-hidden xs:tw-flex">
          <InputGroup id="discover-search">
            <Input
              value={query}
              onChange={debounceChange}
              placeholder="Search mentor"
              onKeyDown={handleKeyPress}
            />
            <InputGroup.Button onMouseDown={() => handleSearch()}>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <div className="xs:tw-mb-4">
          <p className="xs:tw-mb-0 montserrat">Filter By</p>
          <div>
            <FilterItem
              title="Job Role"
              items={filteredIndustries.map((i) => ({
                label: i,
                value: i,
              }))}
              handleAddFilter={handleAddFilter}
              handleClearFilter={handleClearFilter}
              filter={filter}
            />
          </div>
        </div>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} xs={24} md={19} lg={17} xl={17} xxl={15}>
        <div className="tw-flex mb-4 tw-justify-between xs:tw-hidden">
          <h6>Find a Mentor</h6>
          <InputGroup id="discover-search" className="tw-w-80">
            <Input
              value={query}
              onChange={debounceChange}
              placeholder="Search mentor"
              onKeyDown={handleKeyPress}
            />
            <InputGroup.Button onMouseDown={() => handleSearch()}>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>
        </div>
        {/* {!program && (
          <StarterPackBanner
            currentUser={currentUser}
            setShowStarterPackModal={setShowStarterPackModal}
          />
        )} */}
        <div className={`tw-overflow-y-auto no-scrollbar ${height}`}>
          <SearchResult
            program={program}
            currentUser={currentUser}
            mentors={mentors}
            setMentors={setMentors}
            intersectionRef={intersectionRef}
            loading={loading}
          />
        </div>
      </FlexboxGrid.Item>
      {/* <StarterPackModal
        show={showStarterPackModal}
        close={() => setShowStarterPackModal(false)}
      /> */}
    </FlexboxGrid>
  );
};

interface FilterItemProps {
  title: string;
  items: any[];
  handleClearFilter: (title: any) => void;
  handleAddFilter: (title: any, value: any) => void;
  filter: any[];
}

const FilterItem = forwardRef<any, FilterItemProps>((props, ref) => {
  const {
    title,
    items = [],
    handleClearFilter,
    handleAddFilter,
    filter = [],
  } = props;

  return (
    <div ref={ref}>
      <div>
        <h6 className="xs:tw-hidden">{title}</h6>
      </div>
      <CheckboxGroup
        value={filter}
        onChange={handleAddFilter}
        className="xs:tw-hidden"
      >
        {items.map((item, index) => (
          <Checkbox key={index} value={item?.value} className="tw-text-sm">
            {item?.label}
          </Checkbox>
        ))}
      </CheckboxGroup>
      <CheckPicker
        data={items}
        className="tw-w-full xs:tw-block tw-hidden tw-mt-1"
        placeholder={title}
        value={filter}
        onClean={handleClearFilter}
        onChange={handleAddFilter}
      />
    </div>
  );
});

export default Search;
