import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Drawer } from "rsuite";
import EventTicketForm from "../forms/EventTicketForm";
import { Button } from "rsuite";
import portal from "../../../util/portal";

const MobileTicketDrawer = ({
  tickets,
  handleOpenModal,
  event,
  setPurchasedTickets,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Drawer
        placement="bottom"
        open={open}
        onClose={() => setOpen(false)}
        size={tickets?.length > 3 ? "full" : "xs"}
        dialogClassName="tw-rounded-t-md"
      >
        <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-p-6 tw-overflow-y-auto no-scrollbar">
          <div className="tw-text-center">
            <a onClick={() => setOpen(false)}>
              <FaChevronDown className="tw-text-lg" />
            </a>
          </div>
          <div>
            <EventTicketForm
              tickets={tickets}
              handleOpenModal={handleOpenModal}
              setPurchasedTickets={setPurchasedTickets}
              event={event}
              setOpen={setOpen}
            />
          </div>
        </div>
      </Drawer>

      <div
        style={{
          zIndex: 9999,
        }}
        className="tw-fixed tw-bottom-0 tw-z-30 tw-left-0 tw-hidden sm:tw-block tw-w-full tw-py-4 tw-px-8 tw-text-center tw-bg-[#fefefe] tw-border tw-border-solid tw-border-gray-300 tw-rounded-t-md"
      >
        <Button
          appearance="primary"
          className="sm:tw-block tw-appearance-none tw-z-30 tw-bg-[#428ac9]"
          block
          onClick={() => setOpen(true)}
        >
          Reserve my spot
        </Button>
      </div>
    </>
  );
};

export default portal(MobileTicketDrawer);
