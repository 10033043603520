import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import {
  Registration,
  Login,
  Success,
  Dashboard,
  DashboardLayout,
  ForgotPassword,
  ResetPassword,
  ConfirmationWarning,
  CreateEnterprise,
} from "./enterprise";
import {
  EnterpriseProvider,
  useEnterprise,
} from "../contexts/enterpriseContext";
import PortalSetup from "./PortalSetup";
import { NavbarProvider } from "contexts/navbarContext";
import { OnboardingProvider } from "contexts/onboardingContext";

const EnterpriseSetup = ({ state }) => {
  return (
    <Router>
      <EnterpriseProvider initialState={state}>
        <NavbarProvider>
          <OnboardingProvider>
            <Routes>
              <Route element={<PublicAccess />}>
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Route>
              {/* <Route path="/confirm_success" element={<Success />} /> */}
              <Route element={<PrivateAccess />}>
                <Route path="/enterprise" element={<CreateEnterprise />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create" element={<PortalSetup />} />
                <Route path="/edit/:programId" element={<PortalSetup />} />
                <Route
                  path="/program-selection"
                  element={<ProgramSelection />}
                />
              </Route>
            </Routes>
          </OnboardingProvider>
        </NavbarProvider>
      </EnterpriseProvider>
    </Router>
  );
};

const ProgramSelection = () => {
  React.useLayoutEffect(() => {
    window.location.href = "/redirect_main";
  }, []);

  return null;
};

const PublicAccess = () => {
  const { pathname, search } = useLocation();
  const { loggedIn } = useEnterprise();

  const isResetPassword = pathname.includes("reset-password");

  //check if url contains double slash and reset password

  if (isResetPassword && pathname.includes("//")) {
    return (
      <Navigate
        replace
        to={{
          pathname: "/reset-password",
          search: search,
        }}
      />
    );
  }

  if (loggedIn && !isResetPassword) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

const PrivateAccess = () => {
  const { loggedIn, state } = useEnterprise();
  const { pathname } = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (!state.enterprises?.length && pathname !== "/program-selection") {
    return <CreateEnterprise />;
  }

  return (
    <>
      {/* TODO: need to consider this height  */}
      {/* <ConfirmationWarning /> */}
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </>
  );
};

export default EnterpriseSetup;
