// React
import React from "react";

// FutureLab
import UserProfilePhotoUploader from "./UserProfilePhotoUploader";
import UserBannerUploader from "./UserBannerUploader";
import {
  FormInputPicker,
  FormInputText,
  FormTagPicker,
} from "components/form/default/FormInputs";

// UI
import { Form } from "rsuite";

// 3rd Party
import { useFormContext } from "react-hook-form";

const ProfileBasicForm = ({ countries, languages }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="tw-grid tw-gap-4 tw-grid-cols-12 xs:tw-grid-cols-1 xs:tw-gap-0">
        <div className="tw-col-span-5 xs:tw-relative">
          <UserProfilePhotoUploader />
        </div>
        <div className="sm:tw-hidden tw-col-span-7">
          <UserBannerUploader />
        </div>
      </div>
      <div className="tw-grid tw-gap-4 tw-grid-cols-2 xs:tw-grid-cols-1 xs:tw-gap-0 tw-mt-5">
        <Form.Group>
          <FormInputText
            name={"user.firstname"}
            label={"First name"}
            control={control}
            errors={errors}
            size="lg"
          />
        </Form.Group>
        <Form.Group className="xs:tw-mb-6">
          <FormInputText
            name={"user.lastname"}
            label={"Last name"}
            control={control}
            errors={errors}
            size="lg"
          />
        </Form.Group>
      </div>
      <div className="tw-grid tw-gap-4 tw-grid-cols-2 xs:tw-grid-cols-1 xs:tw-gap-0">
        <Form.Group>
          <FormInputPicker
            name="info.country_id"
            placeholder="Please select"
            label="Country"
            block
            control={control}
            errors={errors}
            size="lg"
            data={countries}
          />
        </Form.Group>
        <Form.Group className="tw-mb-6">
          <FormInputPicker
            name={"info.gender"}
            placeholder="Please select"
            label={"Gender"}
            block
            control={control}
            errors={errors}
            size="lg"
            data={[
              {
                value: "male",
                label: "Male",
              },
              {
                value: "female",
                label: "Female",
              },
              {
                value: "others",
                label: "Others",
              },
            ]}
          />
        </Form.Group>
      </div>
      <Form.Group>
        <FormTagPicker
          name="user_languages"
          placeholder="Eg. English, Malay"
          block
          label="Languages spoken"
          control={control}
          errors={errors}
          size="lg"
          data={languages.map((language) => ({
            label: language?.name,
            value: language?.id,
          }))}
        />
      </Form.Group>
    </>
  );
};

export default ProfileBasicForm;
