// React
import React from "react";

// Futurelab
import followMentorImage from "../../../images/follow-3-mentor-navbar-banner.png";

// UI
import { Button } from "rsuite";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function MenteeNavbarBanner() {
  return (
    <>
      <div
        onClick={() => window.open("https://forms.gle/gCApewdYCKNX8HGU7", "_self")}
        className="xs:tw-flex tw-items-center tw-justify-center tw-bg-[#8DBFEB] tw-font-bold tw-text-xs tw-h-[60px] tw-hidden"
      >
        <span className="tw-text-black tw-mr-1">
          Meet the perfect mentor for
        </span>
        <span className="tw-bg-[#0769A1] tw-text-[#FFDF5F] tw-rounded tw-py-1 tw-px-1.5">
          free!
        </span>
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#8DBFEB] tw-font-semibold tw-text-xl sm:tw-text-lg tw-h-[60px] tw-overflow-hidden xs:tw-hidden">
        <div className="tw-mt-1.5">
          <span className="tw-text-black tw-mr-1.5">
            Meet the perfect mentor for
          </span>
          <span className="tw-bg-[#0769A1] tw-text-[#FFDF5F] tw-rounded tw-py-1 tw-px-1.5">
            free!
          </span>
          <img
            src={followMentorImage}
            className="tw-w-28 tw-relative tw-top-3.5 tw-ml-2 tw-overflow-hidden sm:tw-hidden"
          />
        </div>
        <Button
          href="https://forms.gle/gCApewdYCKNX8HGU7"
          endIcon={<MdOutlineKeyboardArrowRight size={24} />}
          className="tw-ml-8 tw-font-semibold tw-text-black"
        >
          Find my match
        </Button>
      </div>
    </>
  );
}
