import { useRef, useState, useEffect } from "react";
import axios from "axios";

const querySearch = async (query, page, filter?) => {
  return await axios.get(`${window.location.href}.json`, {
    headers: {
      "Accept": "application/json",
    },
    params: {
      utf8: "✓",
      search: query,
      commit: "Search",
      industry_selected: filter,
      page,
    },
    withCredentials: true,
  });
};

const useSearch = (initialMentors = [], totalPages = 1) => {
  const [mentors, setMentors] = useState(initialMentors);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [resultsQuery, setResultsQuery] = useState("");
  const firstRender = useRef(true);

  const hasMore = page < totalPages;

  const handleAddFilter = (data) => {
    setFilter(data);
  };

  const handleClearFilter = () => {
    setFilter([]);
  };

  const handleSearch = async (searchQuery = query, filterQuery = filter) => {
    handleUpdateBrowserURL(searchQuery, filterQuery)

    if (loading) return;
    setLoading(true);
    setMentors([]);
    try {
      setResultsQuery(searchQuery);

      const res = await querySearch(searchQuery, 1, filterQuery);
      setMentors(res.data?.data);
      setPage(1);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBrowserURL = (searchQuery, filters) => {
    let url = window.location.pathname;

    if (searchQuery) {
      url = url + '?search=' + encodeURIComponent(searchQuery);
    }

    if (filters.length >= 1) {
      url = url + (searchQuery ? '&' : '?') + "filters=" + encodeURIComponent(filters);
    }

    try {
      window.history.replaceState({}, "", url);
    } catch (err) {
      console.error("handleUpdateBrowserURL: Error updating browser history", err);
    }
  }


  const handlePopularSearch = async (searchQuery) => {
    await handleSearch(searchQuery);
    setQuery(searchQuery);
  };

  const handleFetchMore = async () => {
    if (loading) return;
    if (hasMore) {
      setLoading(true);
      try {
        const res = await querySearch(query, page + 1, filter);
        setMentors([...mentors, ...res.data?.data]);
        setPage(page + 1);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    //check window location search params
    const params = new URLSearchParams(window.location.search);
    const search = params.get("search");
    if (search) {
      setQuery(search);
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) return;
    handleSearch(resultsQuery, filter);
  }, [filter]);

  useEffect(() => {
    if (firstRender.current) {
      //check window location search params
      const params = new URLSearchParams(window.location.search);
      const search = params.get("search");
      const filters = params.get("filters");
      if (search) {
        setResultsQuery(search);
      }

      if (filters && filters.length >= 1) {
        const filtered = decodeURIComponent(filters);
        setFilter(filters.split(","));
      }
      //remove search params
      // window.history.replaceState({}, "", window.location.pathname);
      firstRender.current = false;
      return;
    }
  }, []);

  return {
    setMentors,
    mentors,
    query,
    loading,
    setQuery,
    handleSearch,
    handlePopularSearch,
    filter,
    handleAddFilter,
    handleClearFilter,
    handleFetchMore,
    resultsQuery,
  };
};

export default useSearch;
