import React, { useState } from "react";
import { Button, Message, toaster } from "rsuite";
import { PortalApi } from "../../../../util/portal_api";
import SendFeedbackModal from "./SendFeedbackReminderModal";
import moment from "moment";

const SendFeedbackNotificationButton = ({ programId, feedbackTime }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    if (feedbackTime) {
      const isPast24Hours = is24hours(feedbackTime);
      !isPast24Hours ? setOpenModal(true) : sendFeedbackReminder();
    } else {
      sendFeedbackReminder();
    }
  };

  const is24hours = (time) => {
    const currentTime = moment(time);
    const nextDay = currentTime.clone().add(24, "hours");
    return nextDay.isBefore(moment());
  };

  const sendFeedbackReminder = () => {
    if (openModal == true) {
      setOpenModal(false);
    }

    setLoading(true);
    if (programId) {
      PortalApi({
        method: "post",
        path: "/feedback_management/send_notification",
        options: { program_id: programId },
        success: ({ data }) => {
          setLoading(false);
          toaster.push(
            <Message showIcon type="success">
              {data}
            </Message>
          );
        },
        error: (err) => {
          setLoading(false);
          const { error } = err.response.data;
          toaster.push(
            <Message showIcon type="error">
              {error}
            </Message>
          );
        },
      });
    }
  };

  return (
    <>
      <Button appearance="primary" loading={loading} onClick={handleClick}>
        Send a Feedback Reminder
      </Button>

      <SendFeedbackModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleClick={sendFeedbackReminder}
      />
    </>
  );
};

export default SendFeedbackNotificationButton;
